import anime from 'animejs/lib/anime.es.js';
const PITCHFORK_WIDTH = 70;
class Pitchfork {
    constructor(handler, hostElement){
        this.hostElement = hostElement
        this.handler = handler
        this.pitchfork = null
        this.active = false
        this.readyToDie = false
        this.dying = false
        this.resizeFunction = this.setXPosition.bind(this)
    }

    in() {
        this.active = true
        this.hostElement.insertAdjacentHTML('afterend', '<img src="/static/logos/Devils-fork-LightGrey-1.png" class="nav__pitchfork" />')
        this.pitchfork = document.querySelector('.nav__pitchfork')
        this.setUpXPositioning()
        anime({
            targets: this.pitchfork,
            translateY: 80,
            complete: () => {
                this.readyToDie = true
            } 
        })
    }

    out() {
        if(this.dying){
            return
        }
        this.dying = true
        anime({
            targets: this.pitchfork,
            translateY: 0,
            duration: 100,
            easing: 'easeInQuad',
            complete: () => {
                // Cleanup
                this.active = false
                window.removeEventListener('resize', this.resizeFunction)
                this.pitchfork.parentNode.removeChild(this.pitchfork)
                this.handler.readyForNext()
            } 
        })
    }

    setUpXPositioning(){
        this.setXPosition()
        // Failsafe for initial load
        setTimeout(() => {
            this.setXPosition()
        }, 50)
        window.addEventListener('resize', this.resizeFunction )
    }

    setXPosition(){
        this.pitchfork.style.left = `${this.calcXPositioning()}px`
    }

    calcXPositioning(){
        return this.hostElement.offsetLeft + (this.hostElement.offsetWidth / 2) - (PITCHFORK_WIDTH / 2)
    }
}

export default Pitchfork