import "../scss/main.scss"
import PitchforkHandler from './pitchfork/pitchfork-handler'
import CookiesEuBanner from "cookies-eu-banner/dist/cookies-eu-banner.js"
import BurgerMenu from "./burger-menu"

if (module.hot) {
  module.hot.accept();
}

if(document.querySelector('.glider') !== null){
  new Glider(document.querySelector('.glider'), {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: document.querySelector('.carousel-dots'),
    arrows: {
      prev: document.querySelector('.carousel-prev'),
      next: document.querySelector('.carousel-next')
    },
    responsive: [
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      }
    ]
  })
}



document.addEventListener('DOMContentLoaded', function() {
  const desktopNav = document.querySelector('[class="nav"]')

  new PitchforkHandler(desktopNav.querySelectorAll('.nav__item'))
  new CookiesEuBanner(() => {}, true)
  new BurgerMenu()
})

