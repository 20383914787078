import Pitchfork from './pitchfork'

class PitchforkHandler {
    constructor(elements){
        this.elements = Array.from(elements)
        this.current = new Pitchfork(this, elements[0])
        this.current.in()
        this.bindEvents()
    }

    bindEvents() {
        this.elements.forEach(element => {
            element.addEventListener('mouseenter', () => {
                if(this.current.hostElement === element){
                    return
                }
                this.current.out()
                this.target = element
            })
        });
    }

    readyForNext(){
        this.current = new Pitchfork(this, this.target)
        this.current.in()
    }
}

export default PitchforkHandler;