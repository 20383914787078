class BurgerMenu {
    constructor() {
        this.burgerIcon = document.querySelector('.header__burger')
        this.burgerMenu = document.querySelector('.nav--burger')
        this.burgerCross = document.querySelector('.nav__cross')

        this.toggle()
    }

    toggle() {
        this.burgerIcon.addEventListener('click', () => {
            this.burgerMenu.classList.toggle('nav--unhide')
            document.querySelector('body').classList.add('no-scroll')
        })

        // Just in case this stays open on desktop
        window.addEventListener('resize', () => {
            this.close()
        })

        this.burgerCross.addEventListener('click', () => {
            this.close()
        })

        let navItems = this.burgerMenu.querySelectorAll('.nav__item')

        navItems.forEach((item) => {
            item.addEventListener('click', () => {
                this.close()
            })
        })
    }

    close() {
        this.burgerMenu.classList.remove('nav--unhide')
        document.querySelector('body').classList.remove('no-scroll')
    }
}

export default BurgerMenu